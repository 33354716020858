import React from 'react';

export default function BoxService() {
  return (
    <div style={{ margin: 20 }}>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'center',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>紫荆儿童成长</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>用户服务协议</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>【特别提示】</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1.</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>请您（以下亦称</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>用户</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）仔细阅读本协议全部条款，并确认您已完全了解本协议之规定。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2.</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意，若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>3.</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          请您审慎阅读、充分理解各条款内容，并选择接受或不接受本协议，特别是免除或者限制责任的条款、法律适用和争议解决条款，免除或者限制责任的条款将以粗体标识，您应重点阅读。您的注册、登录、使用等行为将视为对本协议的接受，并视为您同意接受本协议各项条款的约束，本协议将构成您与
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          之间直接有约束力的法律文件。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>4.</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          请确认您依照中华人民共和国法律（为本协议之目的，含行政法规、部门规章、规范性文件，下同）及您所在地之法律、及您自愿或应当接受其管辖之法律（以下合称
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>法律</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ），有权签署并同意接受本协议的约束。如果您代表您的雇主或单位，请在签署前，确认并保证您已获得签署本协议的充分授权。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>一、定义</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          本协议所称
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          是北京点点未来科技发展有限公司及其关联方与其主办的
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”、“
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          紫荆云管理端
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>等</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          微信公众号、网站等传播平台之合称。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          本协议所称关联方是指控制某一方的、或被某一方所控制的、或与某一方共同受控制于同一实体的任何企业。控制是指直接或间接拥有该企业百分之五十（
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>50</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ％）以上的股权、投票权或管理权。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>二、服务内容</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>
          2.1{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          主要是一家网络服务提供者，可针对不同传播渠道用户为其提供
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          信息推送、商品交易支付、内容发布工具等客户服务的
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>SaaS</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>系统</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          ，前述传播渠道包括但不限于：
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2.1.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>通过微信公众号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>的服务；</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2.1.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          提供的可以使用的其他传播渠道。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>
          2.2{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台方北京点点未来科技发展有限公司及其关联方，除特别标明外，并不实际提供商品，平台所售卖的商品或服务均由相应的“紫荆儿童成长中心”售卖主体进行售卖，售卖商品的质量、价格及服务均由相应主体定义和负责，与平台方无关。请您认真查看清楚相应的主体。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>2.</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>3</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}> </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          除免费服务外，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          在平台上的“紫荆儿童成长中心”售卖主体
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          可能提供部分收费服务。您可以在完全自愿的前提下，通过付费方式购买
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>其</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          收费服务。对于收费服务，
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          紫荆儿童成长
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>中心</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          会在您使用之前给予明确的提示，只有根据提示确认同意并完成支付，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          支付已完成
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          的确认通知为准。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>三、服务的变更、中断及终止</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          您理解网络服务具有特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          无法控制的情形），若发生中断或终止部分或全部服务的情况，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          将尽可能及时通过网页公告、系统通知、模版消息、短信或其他合理方式进行通知。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您理解，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>会定期或不定期地对提供服务的</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          平台（包括但不限于公众号服务、网站服务等）进行检修或者维护，如因此类情况而造成服务中断，{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>会尽可能事先进行通告。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>服务的更新。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3.3.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          为了改善用户体验、完善服务内容、
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>&nbsp;</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          保证服务的安全性、功能的一致性，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取网站升级、修改、功能强化、功能改变、版本升级等形式）。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您有权向</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          申请注销您的账号，具体注销条件及程序请参照本协议中的附件《
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销须知》。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>四、商业活动</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您了解并接受，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可能通过紫荆儿童成长微信公众号、手机短信、模版消息、网页或其他合法方式向用户发送商品促销或其他相关商业信息，包括但不限于通知信息、宣传信息、广告信息等。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>五、账号的注册</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>您需要在</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          注册一个账号，并按照
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          的要求为该账号设置密码，才可以使用该账号登录并使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          。您可依照
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          的要求修改您的账号密码、昵称、登录所使用的手机号等信息。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>
          5.2{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          您注册账号时，应按照
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          的提示及要求填写或提供资料、信息，并确保用户身份及信息的真实性、正确性和完整性；如果资料发生变化，您应及时更改。您同意并承诺：
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.2.1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不故意冒用他人信息为您注册账号；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.2.2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          未经他人合法授权不以他人名义注册账号；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.2.3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不使用色情、暴力或侮辱、诽谤他人等违反公序良俗的词语注册账号；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.2.4</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不进行其他不当的账号注册的行为。 如您违反前述规定，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          有权随时拒绝、限制您使用该账号，或者注销该账号。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          我们的一些平台支持您使用第三方平台的账号（例如微信、壹点壹滴家庭教育服务平台等账号）进行登录，如您使用第三方平台的账号登录的，我们将根据您的授权获取该第三方账号下的相关信息（例如：用户名、昵称、头像等，具体以您的授权内容为准）以及身份验证信息。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>您理解，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          账号的所有权归
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          运营方所有，您完成申请注册流程后，获得相应账号的使用权。账号使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。非初始申请注册人不得通过承租、受让或者其他任何方式使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>账号。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>六、账号的使用</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>
          6.1{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          您有责任妥善保管账号信息及密码，您同意在任何情况下不向他人透露账号及密码信息。当在您怀疑账号被他人使用时，应立即通知
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          。您应自行使用账号，并对任何利用您的账号及密码所进行的活动负完全的责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          您应了解，在账号和密码匹配时，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          无法对非法或未经您授权使用您账号及密码的行为作出甄别，因此，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          对任何使用您账号和密码登录并使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          的行为不承担任何责任。您同意并承诺
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>：</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.2.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          当您的账号或密码遭到未获授权的使用，或者发生其他任何安全问题时，您会立即有效通知到
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>，同时您理解</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          对您的任何请求采取行动均需要合理时间，且
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          账号因您主动泄露、遭他人攻击、诈骗等行为导致的损失及后果，您应通过司法、行政等救济途径向侵权行为人追偿。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          有权根据自己的判定，在怀疑账号被不当使用时，拒绝或限制账号使用，或注销该账号，同时终止本协议。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          您同意，遵守法律及本协议规定，秉承善意使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>，且保证：</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会利用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          进行非法活动、或进行侵犯他人权利或损害他人利益的活动；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会以非法方式获取或利用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>其他用户的信息；</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不会以技术方式攻击、破坏或改变
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>的部分或全部、或干扰其运行；</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会以非法方式获取或使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的任何软件、代码或其他技术或商业信息；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.5 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会对</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          运行的任何程序进行反向工程、反向编译、反向汇编或改写；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.6 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会未经许可使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由北京点点未来科技发展有限公司（及关联方）或
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          享有知识产权或权利的信息或资料；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.7 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不会侵犯北京点点未来科技发展有限公司（及关联方）或
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的商标权、著作权、专利权、其他知识产权或其他合法权利或权益，不会以任何方式贬损
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>的商业声誉；</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.8 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不以商业目的使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>，不对</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的任何部分或全部进行商业性质利用、复制、拷贝、出售、调查、广告，不将
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          用于其他任何商业目的或商业性质的活动，但
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>与您另行签订有协议或</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          另行指定可供您使用的特定商业服务除外。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.5.9 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>不会利用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          进行其他违反法律法规或违背公序良俗的活动。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>如您违反上述约定，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门、司法机关。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.6 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您在</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>上传或发表的内容，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          有权进行审核。您应保证您为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出异议，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          有权根据实际情况进行处理，包括但不限于删除相关的内容等，且有权追究您的法律责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}> </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          您应当确保您在
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          所发布的信息不包含以下内容：
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          违反国家法律法规禁止性规定的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          欺诈、虚假、不准确或存在误导性的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.5 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.6 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          存在可能破坏、篡改、删除、影响
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          任何系统正常运行或未经授权秘密获取
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>6.7.7 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          其他违背社会公共利益或公共道德或依据相关
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>规则的规定不适合在</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>上发布的。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>七、隐私保护</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>7.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可能需要您填写能识别用户身份的个人信息以便可以在必要时联系您，或为提供更好的使用体验。
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>个人信息</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          包括但不限于用户的姓名、性别、年龄、联系电话、地址、受教育情况、您使用手机号或第三方账号授权注册所包含的大致的位置信息等。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>7.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          承诺，将按照法律法规规定的方式及保证您安全稳定使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的方式获取、使用、保存、管理您在注册或使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          过程中提供的个人信息，不以非法方式披露您的个人信息。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>7.3</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}> </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>您同意</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          按照《紫荆儿童成长隐私条款》对用户个人信息进行获取、使用、保存、管理和保护，具体条款详见
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          内展示的《紫荆儿童成长隐私条款》。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>八、知识产权</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>8.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          北京点点未来科技发展有限公司对
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>服务及</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          网站、软件所包含的受知识产权法或其他法律保护的资料享有相应的权利。
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的整体内容（包括但不仅限于设计图样以及其他图样、产品、服务名称等）的知识产权归北京点点未来科技发展有限公司所享有，任何人不得使用、复制或用作其他用途。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>8.2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>经由</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          展示、传送的文字、图片、音频、视频及其他内容，受到著作权法、商标法、专利法或其他法律法规的保护，未经北京点点未来科技发展有限公司书面授权许可，您不得自行或许可第三方进行复制、转载、修改、出租、售卖或衍生其他作品。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>8.3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          对其专有内容、原创内容和其他通过授权取得的独占或者独家内容享有知识产权。未经
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          书面许可，任何单位和个人不得私自复制、转载、传播和提供收听服务或者有其他侵犯
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          识产权的行为。否则，将承担法律责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>8.4</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          为了促进知识的分享和传播，您将在
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          上发表的内容（包括但不仅限于留言、评论、作业），授予
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          免费且不可撤销的非独家使用许可，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>有权将该内容用于</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          各种形态的产品和服务上，包括但不限于
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>以及</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          发表的应用或其他互联网产品。您对
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的前述授权并不改变您发布内容的所有权及知识产权归属，也并不影响您行使对所发布内容的合法权利。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>九、免责声明</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>为方便您使用，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可能会提供第三方互联网网站或资源链接。您应了解，除非另有声明，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          无法对第三方网站服务进行控制。您因下载、传播、使用或依赖上述网站或资源所生的损失，由您自行承担责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          尽可能保证其稳定运行。您应理解并同意，因法律、政策、技术、经济、管理的原因，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          不会因以下情形出现而对您承担责任：
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.2.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          无法使用、中断使用或无法完全适合用户的使用要求；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.2.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          受到干扰，无法及时、安全、可靠运行，或出现任何错误；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.2.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>经由</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          取得的任何产品、服务（含收费服务）或其他材料不符合您的期望；
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.2.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          用户资料遭到未经授权的使用或修改。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。如您注销
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>账号，或</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>所提供的服务终止或取消，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可以从服务器上永久地删除用户的数据，除法律有明确规定外，此种情形下，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          无法向用户返还或提供任何数据。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您在使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          服务的过程中应遵守国家法律法规及政策规定，您使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          服务而产生的行为后果由您自行承担。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>9.5 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您通过</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>发布的任何信息，及通过</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>传递的任何观点不代表</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>之立场，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          亦不对其完整性、真实性、准确性或可靠性负责。您对于可能会在
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          上接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行做出判断。在任何情况下，因前述非正当信息而导致的任何损失或伤害，应由相关行为主体承担全部责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十、违约责任</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>10.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>如因</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          违反有关法律、法规或本协议项下的任何条款而给您造成损失的，相应的损害赔偿责任由
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>承担。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 'normal' }}>
          10.2{' '}
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          您同意保障和维护
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          及其他用户的合法权益，如因您违反有关法律、法规或本协议项下的任何条款而给
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          或任何其他任何第三方造成损失，您同意承担由此造成的损害赔偿责任。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十一、协议变更与终止</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>协议变更</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.1.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可根据国家法律、法规或政策变化，保护消费者权益，维护交易秩序的需要，不时修改本协议。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.1.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>本协议如果变更，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>将尽可能提前在</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>发布通知。但您应了解并同意，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          有可能未经事先通知，而修改或变更本协议的全部或部分内容，您也应随时关注本协议是否有修改或变更之情形。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.1.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          如果本协议修改或变更，而您不能接受修改或变更后的内容，您可以停止使用
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>。如果您继续使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ，则表明您完全接受并愿意遵守修改或变更后的本协议。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>协议的中止与终止</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>本协议或本协议项下</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          所提供的服务（含收费服务）可在下述情形下部分或全部中止或终止：
        </span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）因法律规定，或</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          服从行政命令或司法判令的要求；
        </span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）用户违反本协议；</span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）您盗用他人账号、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为的；
        </span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>4</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）在成功注销您的账号后；</span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>认为应予终止的其他情形。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>在一般情形下，</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          会提前按照您提供的联系方式以短信或其他方式通知您，服务将中止或终止。您应了解并同意，在紧急情况或不可抗力的情况下，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>可不经通知即中止或终止服务。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2.3 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>在本协议或本协议项下</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          提供的服务（含收费服务）中止时，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>有权拒绝用户登录</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2.4 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>在本协议或本协议项下</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          提供的服务（含收费服务）终止时，
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>有权：</span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）停止用户登录</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）删除用户信息；</span>
        <br />
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）删除用户购买的内容。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>11.2.5 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          您同意，不因本协议或本协议项下
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          所提供的服务中止或终止，而要求
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          向您作出赔偿或补偿或承担任何其他责任，但法律规定应由紫荆儿童成长承担责任的除外。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十二、通知</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您在注册成为</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>用户并接受</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>服务时，应向</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          提供真实有效的联系方式（包括但不限于联系电话等），对于联系方式发生变更的，您应及时更新有关信息，并保持可被联系的状态。
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          将按照前述联系方式向您发送各类通知，此类通知的内容可能会对您的权利义务产生影响，请您务必及时关注。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十三、法律适用</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您在使用</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          时，应遵守中华人民共和国法律、及您所在地之法律、及您自愿或应当接收其管辖之法律，本协议的解释及履行应按照中华人民共和国法律进行。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十四、争议解决</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您和</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          均同意，因本协议解释或执行引起的任何争议，双方应首先友好协商解决。协商不成时，则任一方均可将争议提交北京仲裁委员会依据其届时有效的仲裁规则以仲裁方式解决。仲裁地点为北京，仲裁语言为中文。仲裁裁决为终局的，对各方均有法律约束力。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>十五、其他</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>15.1 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          可能不时发布针对用户的相关协议，并可能将该相关协议作为对本协议的补充或修改而将其内容作为本协议的一部分。请您及时关注并阅读相关协议。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>15.2 </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>&nbsp;</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>附件</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt', fontWeight: 800 }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>：《</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          “紫荆儿童成长”平台账号
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt', fontWeight: 800 }}>
          注销须知》
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>特别提示：</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          您在申请注销前，应当认真阅读《
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销须知》（以下称</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>“</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销须知</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>”</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）。</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>&nbsp;</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>请您务必审</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          慎阅读、充分理解注销须知中相关条款内容，其中包括：
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）与您约定免除或限制责任的条款；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）其他以粗体标识的重要条款。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          如您对本《注销须知》有任何疑问，可拨打我们的客服电话联系客服，您也可联系在线客服。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          当您完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。如您在阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账号注销程序。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1. </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销的结果</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>，您将无法再使用本</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>，也将无法找回您</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          中及与账号相关的任何内容或信息，紫荆儿童成长亦无法协助您重新恢复该等服务。该等内容或信息包括但不限于：
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）账号信息、会员权益、虚拟资产、已购产品；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）无法使用账号原验证手机登录紫荆儿童成长服务；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）解除与第三方授权或绑定关系；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>4</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>）本</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息、学习数据、证书或其他虚拟头衔等）都将无法找回；
          （
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）您曾获得的优惠券、积分、资格、订单及其他卡券等视为您自行放弃，将无法继续使用。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2. </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          后，您的个人信息我们会在紫荆儿童成长的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关交易记录须在紫荆儿童成长后台保存【
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>】年甚至更长的时间。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3. </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>账号注销条件</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          如果您仍执意注销账号，您的账号需同时满足以下条件：
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>1</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）在最近一个月内，账号没有进行更改密码、更改绑定信息等敏感操作；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>2</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）自愿放弃账号在紫荆儿童成长系统中的资产和虚拟权益自愿放弃；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>3</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）账号内无未完成的订单和服务；
        </span>
        <br />
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>4</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）账号已经解除与其他网站、其他
        </span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>APP</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>的授权登录或绑定关系； </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          widows: 0
        }}
      >
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>（</span>
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          ）账号不存在任何未决争议，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等。
        </span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>4. </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>在账号注销期间，如果您的</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>
          涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，紫荆儿童成长
        </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>平台</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>有权自行终止本</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>的注销而无需另行</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>征求</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>您的同意。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>5. </span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销本</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>并不代表本</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>“紫荆儿童成长”平台账号</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>注销前的账号行为和</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>相关责任豁免或减轻</span>
        <span style={{ fontFamily: '宋体', fontSize: '10.5pt' }}>。</span>
      </p>
      <p
        style={{
          fontSize: '10.5pt',
          lineHeight: '150%',
          margin: '0pt',
          orphans: 0,
          textAlign: 'justify',
          textIndent: '21pt',
          widows: 0
        }}
      >
        <span style={{ fontFamily: 'Calibri', fontSize: '10.5pt' }}>&nbsp;</span>
      </p>
    </div>
  );
}
